hide_lead_flags(0);

$('body').on('click', '[data-action="show-more"]', (e) => {
  e.preventDefault();
  e.stopPropagation();

  const $target = $(e.currentTarget);
  if ($('.lead-flags').hasClass('closed')) {
    $target.text($target.attr('data-opened-text'));
    show_lead_flags();
  }
  else {
    $target.text($target.attr('data-closed-text'));
    hide_lead_flags();
  }
});

function show_lead_flags(speed = 250) {
  const $visible_flag_els = $('.lead-flags').attr('data-visible-flags');
  const $lead_flag_els = $('.lead_flag').slice($visible_flag_els ? $visible_flag_els : 5);
  $lead_flag_els.slideDown(speed);

  $('.lead-flags').addClass('open');
  $('.lead-flags').removeClass('closed');
}

function hide_lead_flags(speed = 250) {
  const $visible_flag_els = $('.lead-flags').attr('data-visible-flags');
  const $lead_flag_els = $('.lead_flag').slice($visible_flag_els ? $visible_flag_els : 5);
  $lead_flag_els.slideUp(speed);

  $('.lead-flags').removeClass('open');
  $('.lead-flags').addClass('closed');
}
